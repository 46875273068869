<!-- Componente de Búsqueda múltiple -->

<template>
  <div class="compMultiple"> 
    <div style="display:flex">
      <!-- Select tipo de Empresa -->   
      <v-select
        style="flex: 1 1 35%"
        v-bind="$select"
        v-model="ctrlAsoc.value"
        :label="ctrlAsoc.label"
        :items="items"
        :disabled="disabled"
        item-value="id"
        item-text="name"
        @change="change_servicio()">         
      </v-select>

      <!-- Finder Empresa -->
      <ctrlfinder style="flex: 1 1 65%"
        :finderName="get_finder"
        :schema="ctrl"
        :fn="fn"
        :edicion="!disabled || (ctrlAsoc.value== '0' || ctrlAsoc.value== '')">
      </ctrlfinder>
    </div>
  </div>
</template>



<script>

  import plugs from "@/common/general_plugs";
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp"); 

  export default {
    components: { ctrlfinder },
    props: {    
      ctrl: { type: Object, default:null },
      fn: { type:Function, default: null },
      schema: { type: Object, default:null },
      disabled: { type:Boolean, default:false }   
    },

    data() {
      return {
        ctrlAsoc: null,
        items:this.ctrl.comp.source
      };
    },


    created() {
      // obtengo el schema del ctrl asociado al ctrl recibido
      this.ctrlAsoc= this.schema.ctrls[this.ctrl.comp.f2];
    },


    computed: {
      get_finder() {
        // obtengo el índice de la empresa seleccionada
        var indexEmp= this.ctrlAsoc.value;

        // obtengo el item de la empresa
        var emp= this.items.find(elem=> elem.id== indexEmp);
        
        if (emp== undefined) return '';        
        return emp.finder;
      }

    },

    methods: {
      // al cambiar el select, inicializo datos del ctrl recibido
      change_servicio() {
        this.ctrl.value= "";
        this.ctrl.comp.valueaux= "";
      }

    },

  };
</script>


